import React, { useEffect, useState } from "react";
import CloseSvg from "../../../assets/images/close.svg";
import Verify from "../../../assets/images/bouncer-icon.svg";
import EmptyCart from "../../../assets/images/empty-cart.svg";
import CartItem from "./CartItem/CartItem";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "../../../index.css";
import i18n from "i18next";

const Cart = (props) => {
  const { toggle, cartRef, cartItems, setCartItems, decodedToken } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const lang = i18n.language;

  useEffect(() => {
    const savedCartItems = JSON.parse(localStorage.getItem("cartItems"))
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [];
    setCartItems(savedCartItems);
  }, []);

  const handleDelete = (id) => {
    let filteredItems = cartItems?.filter((item) => item.id !== id);
    setCartItems(filteredItems);
    localStorage.setItem("cartItems", JSON.stringify(filteredItems));
  };

  const handleVerification = async () => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/generate_session_token`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Secure-Key": `${process.env.REACT_APP_SECURE_KEY}`,
        "Age-Target": "18",
        "Redirect-Url":`${window.location.origin}`,
        Scope: "email",
      },
    };
    const response = await axios.post(url, undefined, config);
    if (response.data.session_token) {
      window.location.href = `${process.env.REACT_APP_BOUNCER_AGE_VERIFICATION_URL}/?session_token=${response.data.session_token}&lang=${lang}`;
      setIsLoading(false);
    }
  };

  return (
    <section className="cart">
      <div className="cart-container" ref={cartRef}>
        <div className="cart-header">
          <div className="cart-header-title">{t("ecommerce.title")}</div>
          <img
            src={CloseSvg}
            onClick={() => {
              toggle();
            }}
            className="cart-close"
            width={20}
            height={20}
            alt=""
          />
        </div>
        {cartItems?.length === 0 ? (
          <div className="cart-body-message">
            <div className="cart-body-message-icon">
              <img src={EmptyCart} alt="" width={80} height={80} />
            </div>
            <div> {t("ecommerce.emptyCart")} </div>
          </div>
        ) : (
          <>
            <div className="cart-body">
              {cartItems?.length !== 0 && (
                <div className="cart-body-shipping">
                  {t("ecommerce.shippingMsg")}
                </div>
              )}
              {cartItems &&
                cartItems?.map((item) => (
                  <>
                    <CartItem
                      item={item}
                      key={item.id}
                      handleDelete={() => handleDelete(item.id)}
                    />
                  </>
                ))}
            </div>
            <div className="cart-footer">
              {decodedToken?.verified ? (
                <>
                  <div className="cart-footer-description-verified">
                    {t("ecommerce.successAlert")}
                  </div>
                  <div className="cart-checkout-cta" onClick={() => {}}>
                    {t("ecommerce.continue")}
                  </div>
                </>
              ) : (
                <>
                  <div className="cart-footer-description">
                    {t("ecommerce.verifyAlert")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="cart-verify-cta"
                    onClick={() => handleVerification()}
                  >
                    {isLoading ? (
                      <div className="spinner" />
                    ) : (
                      <img
                        style={{ width: "20px", marginRight: "10px" }}
                        src={Verify}
                        alt=""
                        width={189}
                      />
                    )}
                    <div style={{ fontSize: "16px", fontWeight: "500" }}>
                      {t("ecommerce.verifyWithBouncer")}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Cart;
